import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import(/* webpackChunkName: "home" */ "@/views/Home/home.vue"),
  //   meta: {
  //     title: 'JEPT-首頁'
  //   }
  // },
  // {
  //   path: "/popupDetection",
  //   name: "PopupDetection",
  //   component: () => import(/* webpackChunkName: "popupDetection" */ "@/views/Hardware/popupDetection.vue"),
  //   meta: {
  //     title: 'JEPT-彈出式視窗檢測'
  //   }
  // },
  {
    // path: "/privacyDetection",
    path: "/",
    name: "PrivacyDetection",
    component: () => import(/* webpackChunkName: "privacyDetection" */ "@/views/Hardware/privacyDetection.vue"),
    meta: {
      title: 'JEPT-隱私設定檢測'
    }
  },
  {
    path: "/confirmDetection",
    name: "ConfirmDetection",
    component: () => import(/* webpackChunkName: "confirmDetection" */ "@/views/Hardware/confirmDetection.vue"),
    meta: {
      title: 'JEPT-再次確認檢測'
    }
  },
  {
    path: "/headphoneDetection",
    name: "HeadphoneDetection",
    component: () => import(/* webpackChunkName: "headphoneDetection" */ "@/views/Hardware/headphoneDetection.vue"),
    meta: {
      title: 'JEPT-耳機檢測'
    }
  },
  {
    path: "/microphoneDetection",
    name: "MicrophoneDetection",
    component: () => import(/* webpackChunkName: "microphoneDetection" */ "@/views/Hardware/microphoneDetection.vue"),
    meta: {
      title: 'JEPT-麥克風檢測'
    }
  },
  {
    path: "/shotDetection",
    name: "ShotDetection",
    component: () => import(/* webpackChunkName: "shotDetection" */ "@/views/Hardware/shotDetection.vue"),
    meta: {
      title: 'JEPT-鏡頭檢測'
    }
  },
  {
    path: "/networkDetection",
    name: "NetworkDetection",
    component: () => import(/* webpackChunkName: "networkDetection" */ "@/views/Hardware/networkDetection.vue"),
    meta: {
      title: 'JEPT-網速檢測'
    }
  },
  {
    path: "/screenSharing",
    name: "ScreenSharing",
    component: () => import(/* webpackChunkName: "screenSharing" */ "@/views/Hardware/screenSharing.vue"),
    meta: {
      title: 'JEPT-螢幕分享檢測'
    }
  },
  {
    path: "/notes",
    name: "Notes",
    component: () => import(/* webpackChunkName: "notes" */ "@/views/Remind/notes.vue"),
    meta: {
      title: 'JEPT-提醒事項'
    }
  },
  {
    path: "/examinationOver",
    name: "ExaminationOver",
    component: () => import(/* webpackChunkName: "examinationOver" */ "@/views/Examination/examinationOver.vue"),
    meta: {
      title: 'JEPT-檢測結束'
    }
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;
