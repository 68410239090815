<template>
  <div class="NavHeader">
    <div class="box">
      <div class="box-left">
        <img src="@/assets/image/test/header.png" alt="logo">
      </div>
      <div class="box-right">
        <!-- type == 1 硬件檢測 -->
        <div class="box-right-type" v-if="type == 1">
          <img src="@/assets/image/test/type1.png" alt="硬體檢測">
          硬體檢測
        </div>
        <!-- type == 2 提醒事項 -->
        <div class="box-right-type" v-if="type == 2">
          <img src="@/assets/image/test/type2.png" alt="提醒事項">
          提醒事項
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavHeader",
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<style lang="less" scoped>
.NavHeader {
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  user-select: none;
  z-index: 99;
  .box {
    margin: 0 auto;
    width: 1320px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-left {
      width: 284px;
      height: 45px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-right {
      display: flex;
      align-items: center;
      .box-right-type {
        display: flex;
        align-items: center;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 40px;
        color: #333333;
        img {
          margin-right: 8px;
          width: 32px;
          height: 32px;
        }
      }
      .box-right-button {
        user-select: none;
        cursor: pointer;
        margin-left: 30px;
        width: 240px;
        height: 45px;
        background: #E2664F;
        border-radius: 22.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }
    }
  }
}
</style>