import router from "./router";

// 路由守卫
router.beforeEach(async(to, form, next) => {
  // 设置页面title
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'JEPT' //此处写默认的title
  }
  // 设置api白名单
  const whiteList = ['/']
  // 白名单的不做验证
  if (whiteList.includes(to.path)) {
    next()
  } else {
    if (sessionStorage.getItem('testLevel')) {
      next()
    } else {
      next('/')
    }
  }
})